import { DateTime } from "luxon";
import { BiMessage } from "react-icons/bi";

export default function Footer(props) {
  let actualYear = DateTime.now().get("year");

  return (
    <div
      className={`d-flex justify-content-between mb-3 footer ${
        props.isReport ? "footer-report" : ""
      }`}
    >
      <div className="d-flex">
        <div className="mt-3">Version 1.3</div>
        <a
          className="link-text mt-3 ms-3"
          href="mailto:asuitesupport@itx.com"
        >
          <BiMessage className="icon-time-reports me-1" />
          <span style={{ textDecoration: "underline" }}>Provide Feedback</span>
        </a>
      </div>
      <div className="mt-3">©{actualYear} ITX Corp.</div>
    </div>
  );
}
